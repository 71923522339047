<template>
	<SubPageHeader :headerTitle="getHeaderTitle" v-if="this.$route.meta.headerTitle != false"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component" :uniqueId="0">

			</component>
		</div>
	</div>
</template>

<script>
import SubPageHeader from '../SubPageHeader.vue'
import EChartFilesOverview from '../../components/patient/echart/files/filesOverview.vue';
import EChartFilesEdit from '../../components/patient/echart/files/filesAdd.vue';
import EChartFilesDelete from '../../components/patient/echart/files/filesOverview.vue';
import EChartFilesCreate from '../../components/patient/echart/files/filesAdd.vue';

export default {
	data() {
			return {
		
		}
	},
	components: {
		SubPageHeader,
		EChartFilesOverview,
        EChartFilesEdit,
        EChartFilesDelete,
		EChartFilesCreate
	},
	created() { 
		this.$store.state.component = this.$route.name;
	},
	mounted() {

	},
	methods: {
	},
	computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
  },
}
</script>